import { useLocation, useNavigate } from '@remix-run/react';
import pluralize from 'pluralize';
import { Fragment, useMemo, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import useSWRMutation, { type SWRMutationResponse } from 'swr/mutation';
import { match } from 'ts-pattern';

import {
  type DtoBlock,
  type DtoGamePack,
  type DtoSharedAsset,
  type DtoSingleGamePackResponse,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { apiService } from '../../../services/api-service';
import { type GamePack } from '../../../types/game';
import { fromDTOGamePack, fromMediaDTO } from '../../../utils/api-dto';
import { err2s } from '../../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { AIIcon } from '../../icons/AIIcon';
import { Loading } from '../../Loading';
import { SharedAssetCover } from '../../SharedAsset';
import { GamePackCover } from '../Utilities';
import { activityGenAIAction } from './ActivityEditor';
import { CustomGamePackContainer } from './CustomGamePackLayout';
import { CustomGamePackPromptEditor } from './CustomGamePackPromptEditor';
import { CustomGamePackHeader, CustomGamePackHeaderLeft } from './Nav';
import { type Activity } from './types';
import { log, UGCUtils } from './utils';

function Introduction(props: { template: DtoGamePack }) {
  return (
    <div
      className='w-full px-8 py-7.5 bg-modal 
          border border-secondary rounded-xl flex flex-col'
    >
      <div className='flex items-start gap-9'>
        <div className='flex-shrink-0 w-56'>
          <GamePackCover pack={fromDTOGamePack(props.template)} />
        </div>
        <div className='flex flex-col gap-6'>
          <div className='font-bold'>{props.template.name}</div>
          <div className='text-sms'>
            {props.template.ugcSettings?.userDirection}
          </div>
        </div>
      </div>
    </div>
  );
}

function RightPanel(props: { template: DtoGamePack; onCreate: () => void }) {
  return (
    <div className='w-full h-full flex items-end justify-end'>
      <button
        type='button'
        className='text-icon-gray font-medium mr-8 mb-4 hover:text-white'
        onClick={props.onCreate}
      >
        Create Manually
      </button>
    </div>
  );
}

function ActivityPreview(props: { activity: Activity }) {
  const { activity } = props;
  const { asset } = activity;

  return (
    <div
      className='w-full min-h-21 bg-modal border border-secondary rounded-xl 
    flex items-center p-2.5 gap-3.5'
    >
      <div className='w-28 flex-shrink-0' style={{ aspectRatio: '16/9' }}>
        <SharedAssetCover
          media={fromMediaDTO(asset.media)}
          className='w-full h-full'
        />
      </div>
      <div className='h-full flex flex-col gap-2'>
        <p className='text-white text-xl font-bold'>{asset.primaryText}</p>
        <p className='text-secondary'>{asset.secondaryText}</p>
      </div>
    </div>
  );
}

function CreatedGamePack(props: {
  pack: DtoGamePack | GamePack | undefined;
  blocks: DtoBlock[];
  sharedAssets: DtoSharedAsset[];
  name: string;
}) {
  const { pack, blocks, sharedAssets } = props;

  const activities = useMemo(
    () => UGCUtils.MakeActivities(pack, blocks, sharedAssets),
    [pack, blocks, sharedAssets]
  );

  if (!pack) return null;

  return (
    <div className='w-full flex flex-col items-center justify-center gap-3 self-start'>
      <p className='text-xl font-bold text-center'>
        Your <span className='text-tertiary'>{props.name}</span> Game Was
        <br />
        Generated Successfully
      </p>
      <p className='text-secondary'>
        There are {activities.length} {pluralize('activity', activities.length)}{' '}
        that make up this game
      </p>
      <div className='w-full flex flex-col gap-2.5'>
        {activities.map((a) => (
          <ActivityPreview key={a.block.id} activity={a} />
        ))}
      </div>
    </div>
  );
}

function CreateCustomGamePackModal(props: {
  swr: SWRMutationResponse<DtoSingleGamePackResponse>;
  name: string;
  onConfirm: () => void;
  onClose: () => void;
}) {
  const { swr, name } = props;
  const location = useLocation();

  const onContinue = () => {
    if (!swr.data?.gamePack) return;
    // We will see the empty page after navigate and close the modal.
    // Use _window.location.replace_ instead.
    // nagivate(`/custom-games/${swr.data.gamePack.id}/edit`, { replace: true });
    // props.onConfirm();
    const params = new URLSearchParams(location.search);
    params.set('created', 'true');
    window.location.replace(
      `/custom-games/${swr.data.gamePack.id}/edit?${params.toString()}`
    );
  };

  return (
    <ModalWrapper borderStyle='gray' containerClassName='w-180'>
      <div className='w-full min-h-120 flex flex-col items-center justify-center px-15 py-10 relative gap-10'>
        <div className='w-full flex-1 flex items-center justify-center'>
          {match(swr)
            .when(
              () => swr.isMutating,
              () => (
                <Loading
                  containerClassName='flex-col'
                  imgClassName='w-20 h-20'
                  text='Creating in Progress...'
                />
              )
            )
            .when(
              () => !!swr.error,
              () => (
                <div className='text-sms text-red-002'>{err2s(swr.error)}</div>
              )
            )
            .otherwise(() => (
              <CreatedGamePack
                pack={swr.data?.gamePack}
                name={name}
                blocks={swr.data?.blocks ?? []}
                sharedAssets={swr.data?.linkedSharedAssets ?? []}
              />
            ))}
        </div>
        <div className='flex items-center justify-center'>
          <button
            type='button'
            className='btn-primary w-40 h-10'
            disabled={swr.isMutating || !!swr.error || !swr.data?.gamePack}
            onClick={onContinue}
          >
            Customize
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

function CustomGamePackStarterInternal(props: {
  template: DtoGamePack;
  name: string;
  enableAI: boolean;
}) {
  const { template, name } = props;
  const created = useRef<DtoSingleGamePackResponse | null>(null);

  const swrCreateGamePack = useSWRMutation(
    `/game-packs/${template.id}/customize`,
    async () => {
      if (!created.current) {
        const resp = await apiService.gamePack.customize(template.id, { name });
        created.current = resp.data;
        return resp.data;
      }
      return created.current;
    }
  );

  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const openModal = async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <CreateCustomGamePackModal
          swr={swrCreateGamePack}
          name={name}
          onClose={p.internalOnCancel}
          onConfirm={p.internalOnConfirm}
        />
      ),
    });
  };

  const createEmptyGamePack = () => {
    swrCreateGamePack.trigger();
    openModal();
  };

  const {
    state: { state },
    call: createGamePackWithAI,
  } = useLiveAsyncCall(async (prompt: string) => {
    try {
      const resp = await swrCreateGamePack.trigger();
      if (!resp) return false;
      const activities = UGCUtils.MakeActivities(
        resp.gamePack,
        resp.blocks ?? [],
        resp.linkedSharedAssets ?? []
      );
      const promises = activities.map((activity) =>
        activityGenAIAction(resp.gamePack.id, activity.block, prompt)
      );
      await Promise.all(promises);
      openModal();
      return true;
    } catch (error) {
      log.error('Failed to create game pack with AI', error, {
        prompt,
        templatePackId: template.id,
      });
      throw error;
    }
  });

  useEffectOnce(() => {
    if (props.enableAI) return;
    createEmptyGamePack();
  });

  return (
    <CustomGamePackContainer
      right={<RightPanel {...props} onCreate={createEmptyGamePack} />}
    >
      <div className='w-full h-full flex flex-col items-center text-white'>
        <div className='w-160 h-full flex flex-col items-center mt-34 mb-2'>
          {state.isRunning ? (
            <div className='w-full h-50 flex items-center justify-center'>
              <div
                className='w-45 h-16 flex items-center justify-center bg-black 
            rounded-xl border-2 border-secondary'
              >
                <Loading text='Thinking...' />
              </div>
            </div>
          ) : (
            <>
              <div className='flex items-center justify-center gap-4 mb-7.5'>
                <AIIcon className='w-6 h-6 fill-current' />
                <p className='text-xl font-bold'>
                  Let’s customize your game with AI!
                </p>
              </div>
              <Introduction {...props} />
            </>
          )}
          <CustomGamePackPromptEditor
            enabled={props.enableAI}
            onSubmit={createGamePackWithAI}
            isSubmitting={state.isRunning}
            className='mt-auto py-4'
            active
            autoFocus
          />
        </div>
      </div>
    </CustomGamePackContainer>
  );
}

export function CustomGamePackStarter(props: {
  template: DtoGamePack;
  defaultName: string;
  enableAI: boolean;
}) {
  const nagivate = useNavigate();
  const [name, setName] = useState(props.defaultName);

  return (
    <Fragment>
      <CustomGamePackHeader
        left={
          <CustomGamePackHeaderLeft
            title={name}
            onClickBack={() => nagivate(-1)}
            onTitleChange={(t) => setName(t)}
          />
        }
      />
      <CustomGamePackStarterInternal {...props} name={name} />
    </Fragment>
  );
}
